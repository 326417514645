import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Paguina web
import Inicio from './Pantallas/Inicio';
import Instalaciones from './Pantallas/Instalaciones';
import Suscripciones from "./Pantallas/Suscripciones";
import Formulario from "./Pantallas/Formulario";
import InicioSesionCliente from './Pantallas/InicioSecionCliente';
import InicioSecionTrabajador from './Pantallas/InicioSecionTrabagador'; 
import Nosotros from './Pantallas/Nosotros';

// Colaboradoeres 
import InicioColaboradoras from './Colaboradores/InicioColaboradoras '; // Corrección aquí
import BusquedaCliente from './Colaboradores/BusquedaCliente';
import Capacitacion from './Colaboradores/Capacitacion';
import Monitoreo from './Colaboradores/Reporte';
import RegistroCliente from "./Colaboradores/RegistroCliente";
import EditarUsuario from "./Colaboradores/EditarUsuario";


// Clientes 
import InicioCliente from './Client/InicioClient';
import RutinasVideos from  './Client/RutinasVideos';
import GraficaProgreso from './Client/GraficaProgreso';
import Medicion from './Client/Medicion';


// // Dansa aeria 
// import Navbar from './DansaAeria/components/Navbar';
// import Footer from './DansaAeria/components/Footer';
// import Home from './DansaAeria/pages/Home';
// //import Noticias from './pages/Noticias';
// import Formas from './DansaAeria/pages/Galeria'; 
// import Videos from "./DansaAeria/pages/VideoGallery";
// import SobreNosotros from './DansaAeria/pages/SobreNosotros';
// import SocialMedia from './DansaAeria/pages/SocialMedia';

createRoot(document.getElementById('root')).render(
  <Router> 
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/Instalaciones" element={<Instalaciones />} />
      <Route path="/Nosotros" element={<Nosotros />} />
      <Route path="/Suscripciones" element={<Suscripciones />} />
      <Route path="/Formulario" element={<Formulario />} />
      <Route path="/InicioSesionCliente" element={<InicioSesionCliente />} />
      <Route path="/InicioSesionTrabajador" element={<InicioSecionTrabajador />} />

      <Route path="/IniCIoCoLaborAdores" element={<InicioColaboradoras />} /> {/* Corrección aquí */}
      <Route path="/BusquedaCliente" element={<BusquedaCliente/>} />
      <Route path="/RegistroCliente" element={<RegistroCliente/>}/>
      <Route path="/Capacitacion" element={<Capacitacion/>} />
      <Route path="/Monitoreo" element={<Monitoreo/>} />
      <Route path="/EditarUsuario" element={<EditarUsuario/>}/>

      <Route path="/InICioClIeNte" element={<InicioCliente/>}/>
      <Route path="/RutinasVideos" element={<RutinasVideos/>}/>
      <Route path="/GraficaProgreso" element={<GraficaProgreso/>}/>
      <Route path="/Medicion" element={<Medicion/>}/>


      
      {/* <Route path="/Home" element={<Home/>}/>
      <Route path="/Navbar" element={<Navbar/>}/>
      <Route path="/Footer" element={<Footer/>}/>
      <Route path="/Formas" element={<Formas/>}/>
      <Route path="/Videos" element={<Videos/>}/>
      <Route path="/SobreNosotros" element={<SobreNosotros/>}/>
      <Route path="/SocialMedia" element={<SocialMedia/>}/> */}


    </Routes>
  </Router>
);
