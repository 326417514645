import React from 'react';
import { Link } from 'react-router-dom';
import '../Style/Colaboradores/InicioColaboradoras .css';

const InicioColaboradoras = () => {
  return (
    <div className="inicio-colaboradoras">
      <h1>Inicio Colaboradoras</h1>
      <div className="botones">
        <Link to="/BusquedaCliente" className="boton">Búsqueda de cliente</Link>
        <Link to="/Capacitacion" className="boton">Capacitación</Link>
        <Link to="/RegistroCliente" className="boton">Nuevo Cliente</Link>
        <Link to="/Monitoreo" className="boton">Reporte</Link>
        <Link to="/EditarUsuario" className="boton">Editor de datos de clientes</Link>
        <Link to="/GraficaProgreso" className="boton">Graficación de las socias</Link>

      </div>
    </div>
  );
}

export default InicioColaboradoras;
