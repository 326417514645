import React from 'react';
import Slider from 'react-slick';
import "../Style/PrimeraParte/Nosotros.css";

import Logo from "../image/Logo/logo-01.png";
// Entrenadoras 
import Entrenadoras from '../image/Entrenadoras/Individual/IMG_20240720_142159.jpg';
import Entrenadoras1 from '../image/Entrenadoras/Individual/IMG_20240720_142242(1).jpg';
import Entrenadoras2 from '../image/Entrenadoras/Individual/IMG_20240720_142242.jpg';
import Entrenadoras3 from '../image/Entrenadoras/Individual/IMG_20240720_142335.jpg';
import Entrenadoras4 from '../image/Entrenadoras/Individual/IMG_20240720_142422.jpg'

import Imagen1 from "../image/Entrenadoras/Entrenadores 1.jpg";
import Imagen2 from "../image/Entrenadoras/Entrenadora 2.jpg";
import Imagen3 from "../image/Entrenadoras/Entrenadores 3.jpg";
import Imagen4 from "../image/Entrenadoras/Entrenadores 4.jpg";

// Redes sociales 
import facebookIcon from '../image/Redes sociales/Facebok.png';
import instagramIcon from '../image/Redes sociales/Imatragram.png';
import whatsappIcon from '../image/Redes sociales/whatsapp.png';
import tiktokIcon from '../image/Redes sociales/tiktok.png';

const Nosotros = () => {
  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  return (
    <div className="contenedor-nosotros">
      <header className="encabezado-nosotros">
        <div className="logo-nosotros">
          <img src={Logo} alt="Backside Siluet" loading="lazy" />
        </div>
        <nav className="nav-nosotros">
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/Suscripciones">Suscripciones</a></li>
            <li><a href="/Instalaciones">Instalaciones</a></li>
            <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
            <li className="nav-derecha"><a href="/Nosotros">Sobre Nosotros</a></li>
          </ul>
        </nav>
        <div className="iconos-sociales">
          <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw==" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://wa.me/5212381783232" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
          <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" />
          </a> 
        </div>
      </header>

      
      <div className="contenido-nosotros">

        {/* Sección con texto e imagen */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="texto">
              <h2 className="titulo-nosotros">Únete a nosotras</h2>
              <p>
                ¡Únete a nosotras y comienza tu viaje hacia una vida más saludable, feliz y segura! En Backside Siluet, no solo mejoramos tu cuerpo, sino también tu mente y espíritu. Ven y descubre cómo podemos ayudarte a ser la mejor versión de ti misma. ¡Te esperamos con los brazos abiertos!
              </p>
              <button className="boton-unirse" onClick={() => window.location.href='/Suscripciones'}>Suscripciones</button>
              <button className="boton-prueba-gratis" onClick={() => window.location.href='/Formulario'}>cortesía de Bienvenida gratis</button>
            </div>
            <div className="imagen">
              <img src={Imagen1} alt="Imagen relacionada 1" loading="lazy" />
            </div>
          </div>
        </section>

        {/* Sección de Misión */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="imagen">
              <img src={Imagen2} alt="Imagen relacionada 2" loading="lazy" />
            </div>
            <div className="texto">
              <h2 className="titulo-nosotros">Misión</h2>
              <p>
                Nuestra misión es transformar y empoderar a las mujeres en los ámbitos físico, emocional y social a través del exclusivo sistema de entrenamiento Backside Siluet. Con un acompañamiento profesional constante, buscamos crear una comunidad de mujeres fuertes y seguras de sí mismas.
              </p>
            </div>
          </div>
        </section>

        {/* Sección de Visión */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="texto">
              <h2 className="titulo-nosotros">Visión</h2>
              <p>
                Nuestra visión es convertirnos en la empresa líder de centros de entrenamiento integral para mujeres en nuestro país, siendo la opción preferida para nuestras clientas y colaboradoras. Nos comprometemos a ofrecer una experiencia única e innovadora que fomente el bienestar y el crecimiento personal.
              </p>
            </div>
            <div className="imagen">
              <img src={Imagen3} alt="Imagen relacionada 3" loading="lazy" />
            </div>
          </div>
        </section>

        {/* Sección de Actividades y Comunidad */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="imagen">
              <img src={Imagen4} alt="Imagen relacionada 4" loading="lazy" />
            </div>
            <div className="texto">
              <h2 className="titulo-nosotros">Actividades y Comunidad</h2>
              <p>
                En Backside Siluet, siempre pensamos en nuestras socias. Por eso, realizamos constantemente actividades que ayudan a las mujeres, fomentando un ambiente de apoyo y crecimiento mutuo. Creemos en la fuerza de la comunidad y trabajamos para que cada mujer que se une a nosotros se sienta parte de una red solidaria y motivadora.
              </p>
            </div>
          </div>
        </section>

        {/* Sección de Colaboradoras */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-carrusel">
            <div className="texto">
              <h2 className="titulo-nosotros">Colaboradoras</h2>
              <p>
                En Backside Siluet, nuestras entrenadoras, capacitadas en nuestro exclusivo sistema de entrenamiento, están dedicadas apasionadamente a ayudarte a alcanzar tus metas y superar tus límites. Ofrecen apoyo constante e individualizado, combinando una excelente formación y experiencia con una auténtica preocupación por tu bienestar y éxito. Su compromiso y dedicación te brindarán una experiencia única y transformadora. Ven y conoce a nuestro increíble equipo, listo para acompañarte en cada paso de tu viaje hacia una vida más saludable y feliz.
              </p>
            </div>
             {/* Carrusel de imágenes */}
        <div className="carrusel-contenedor">
          <Slider {...settings} className="carrusel-nosotros">
            <div>
              <img src={Entrenadoras} alt="Imagen 1" loading="lazy" />
            </div>
            <div>
              <img src={Entrenadoras1} alt="Imagen 2" loading="lazy" />
            </div>
            <div>
              <img src={Entrenadoras2} alt="Imagen 3" loading="lazy" />
            </div>
            <div>
              <img src={Entrenadoras3} alt="Imagen 4" loading="lazy" />
            </div>
            <div>
              <img src={Entrenadoras4} alt="Imagen 5" loading="lazy" />
            </div>
          </Slider>
        </div>
          </div>
        </section>

        {/* Sección de Eventos en la Comunidad Backside */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="imagen">
              <img src={Imagen2} alt="Imagen relacionada eventos" loading="lazy" />
            </div>
            <div className="texto">
              <h2 className="titulo-nosotros">Eventos en la Comunidad Backside</h2>
              <p>
                En Backside, organizamos anualmente una variedad de eventos y actividades diseñadas para fomentar una comunidad de apoyo, romper la rutina y desafiar tus límites. Desde carreras y caminatas hasta excursiones a cerros, competencias deportivas IRON, retos de fitness y pasarelas de bienestar, nuestras iniciativas te invitan a salir de tu zona de confort y descubrir nuevas fortalezas. Únete a nosotras y disfruta de una experiencia que fortalece tanto tu cuerpo como tu espíritu, en un ambiente solidario y motivador. ¡Ven y descubre todo lo que tenemos preparado para ti!
              </p>
            </div>
          </div>
        </section>

        {/* Sección de Sistema de Entrenamiento Backside */}
        <section className="seccion-nosotros enmarcado">
          <div className="texto-con-imagen">
            <div className="texto">
              <h2 className="titulo-nosotros">Sistema de Entrenamiento Backside</h2>
              <p>
                En Backside Siluet hemos desarrollado nuestros propios sistemas de entrenamiento únicos y especializados para mujeres. Utilizamos una estrategia divertida y diferente, alternando distintos tipos de esfuerzo físico, intensidades, tiempos y repeticiones, teniendo como indicador principal la frecuencia cardíaca. Esto permite entrenamientos eficientes y personalizados, asegurando que cada mujer pueda alcanzar sus metas de la manera más efectiva y segura posible.
              </p>
            </div>
            <div className="imagen">
              <img src={Imagen3} alt="Imagen relacionada con el entrenamiento" loading="lazy" />
            </div>
          </div>
        </section>
      </div>
      
      <footer className="pie-de-pagina">
        <div className="footer-content">
          <div className="footer-logoInicio">
            <img src={Logo} alt="Logo del Club" loading="lazy" />
          </div>
          <div className="footer-info">
            <h3>Contacto</h3>
            <p>Dirección: 3 norte #206 centro Tehuacán, puebla.</p>
            <p>Teléfono: +52 238 178 3232</p>
            <p>Email: backside.siluet@gmail.com</p>
          </div>
          <div className="footer-links">
            <h3>Enlaces Rápidos</h3>
            <ul>
              <li><a href="/">Inicio</a></li>
              <li><a href="/Instalaciones">Instructores</a></li>
              <li><a href="/Suscripciones">Precios</a></li>
              <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <h3>Síguenos</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL"><img src={facebookIcon} alt="Facebook" loading="lazy" /></a>
              <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw=="><img src={instagramIcon} alt="Instagram" loading="lazy" /></a>
              <a href="https://wa.me/5212381783232"><img src={whatsappIcon} alt="WhatsApp" loading="lazy" /></a>
              <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1"><img src={tiktokIcon} alt="TikTok" loading="lazy" /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Gimnasio para Mujeres Backside Siluet. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default Nosotros;
