import React, { useState, useEffect } from 'react';
import '../Style/Client/RutinasVideos.css';

const abdomenVideos = [
  { id: 'https://youtu.be/oDXss4aqODs?si=DLfFUaoxAjNvldqd', title: 'Rutina Abdomen #1' },
  { id: 'https://youtu.be/-eObDXbH-WM?si=SPyt1p9BnbzfPbBY', title: 'Rutina Abdomen #2' },
  { id: 'https://youtu.be/e0D_8GPQzBk?si=WiScusgm21cMF31I', title: 'Rutina Abdomen #3' },
  { id: 'https://www.youtube.com/watch?v=e2RabUjanHI&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=6', title: 'Rutina Abdomen #4'},
  { id: 'https://www.youtube.com/watch?v=pWpAp3mPRGY&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=4', title: 'Rutina Abdomen #5'},
  { id: 'https://www.youtube.com/watch?v=MtkZ6GQVYtM&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=2', title: 'Rutina Abdomen #6'},
  { id: 'https://www.youtube.com/watch?v=WG7FvLrg1eE&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=1', title: 'Rutina Abdomen #7'},
  { id: 'https://www.youtube.com/watch?v=Esygsc-aSWg', title: "Rutina Abdomen #8" }
];

const cardioVideos = [
  { id: 'https://youtu.be/qyXyROi3KPU?si=d0F5RAHsj1iNetJj', title: 'Rutina Cardio #1' },
  { id: 'https://youtu.be/K5muZ6a0VAM?si=ICnfUg9ZawaQWY22', title: 'Rutina Cardio #2' },
  { id: 'https://youtu.be/CSsLG33YTLI?si=FZFFMLnAswSCkG3H', title: 'Rutina Cardio #3' },
  { id: 'https://youtu.be/oClbTVN8HSg?si=he2tt_4sRwdWXu-D', title: 'Rutina Cardio #4' },
  { id: 'https://youtu.be/rye6Tt1T2bY?si=QhNTMP1Y6vF23W1e', title: 'Rutina Cardio #5' },
  { id: 'https://www.youtube.com/watch?v=866G5vHE5SE&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=7', title: 'Rutina Cardio #6' },
  { id: 'https://www.youtube.com/watch?v=WNxI4k5WoEo&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=5', title: 'Rutina Cardio #7' },
  { id: 'https://www.youtube.com/watch?v=1bOLYlp192k&list=UULFDt6C8vtaDZF0rq93wKhc5w&index=3', title: 'Rutina Cardio #8' }
];

const RutinasVideos = () => {
  const [videos, setVideos] = useState([]);
  const [videoCategory, setVideoCategory] = useState('');

  const handleCategoryChange = (category) => {
    setVideoCategory(category);
    if (category === 'abdomen') {
      setVideos(abdomenVideos);
    } else if (category === 'cardio') {
      setVideos(cardioVideos);
    }
  };

  return (
    <div className="rutinas">
      <h1>Rutinas de Ejercicio</h1>
      <div className="button-container">
        <button onClick={() => handleCategoryChange('abdomen')} className="category-button">
          Videos de Abdomen
        </button>
        <button onClick={() => handleCategoryChange('cardio')} className="category-button">
          Videos de Cardio
        </button>
      </div>
      <div className="video-list">
        {videos.map(video => (
          <div key={video.id} className="video-item">
            <iframe
              title={video.title}
              src={`https://www.youtube.com/embed/${video.id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-frame"
            ></iframe>
            <h3>{video.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RutinasVideos;