import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Style/Colaboradores/Registro.css'


const Registro = () => {
    const [formData, setFormData] = useState({
      nombreDeUsuario: '',
      contrasena: '',
      numeroDeCliente: '',
      turno: '',
      nombre: '',
      descripcion: '',
      fechaDeNacimiento: '',
      edad: '',
      numeroTelefonico: '',
      correoElectronico: '',
      ocupacion: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(''); // Reset error before new request
      setSuccess('');
      try {
        const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios/registrar', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          setSuccess('Usuario registrado exitosamente');
          navigate('/InicioColaboradores'); // Redirigir al usuario a la página de inicio de sesión
        } else {
          const data = await response.json();
          setError(data.msg || 'Error en el registro');
        }
      } catch (error) {
        setError('Error en el servidor');
      }
    };
  
    return (
      <div className="container">
        <div className="registro-section">
          <h2>Registro de Usuario</h2>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="nombreDeUsuario">Nombre de Usuario:</label>
              <input
                type="text"
                id="nombreDeUsuario"
                name="nombreDeUsuario"
                value={formData.nombreDeUsuario}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="contrasena">Contraseña:</label>
              <input
                type="password"
                id="contrasena"
                name="contrasena"
                value={formData.contrasena}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="numeroDeCliente">Número de Cliente:</label>
              <input
                type="text"
                id="numeroDeCliente"
                name="numeroDeCliente"
                value={formData.numeroDeCliente}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="turno">Turno:</label>
              <input
                type="text"
                id="turno"
                name="turno"
                value={formData.turno}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="nombre">Nombre:</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="descripcion">Descripción:</label>
              <input
                type="text"
                id="descripcion"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="fechaDeNacimiento">Fecha de Nacimiento:</label>
              <input
                type="date"
                id="fechaDeNacimiento"
                name="fechaDeNacimiento"
                value={formData.fechaDeNacimiento}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="edad">Edad:</label>
              <input
                type="number"
                id="edad"
                name="edad"
                value={formData.edad}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="numeroTelefonico">Número Telefónico:</label>
              <input
                type="text"
                id="numeroTelefonico"
                name="numeroTelefonico"
                value={formData.numeroTelefonico}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="correoElectronico">Correo Electrónico:</label>
              <input
                type="email"
                id="correoElectronico"
                name="correoElectronico"
                value={formData.correoElectronico}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ocupacion">Ocupación:</label>
              <input
                type="text"
                id="ocupacion"
                name="ocupacion"
                value={formData.ocupacion}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="button">Registrar</button>

            {/* <Link to="/InicioColaboradores">
              <button type="button" className="button">Registrar</button>
            </Link> */}

          </form>
        </div>
      </div>
    );
  };
  
  export default Registro;