import React from 'react';
import '../Style/PrimeraParte/Suscripciones.css'; 
import Logo from '../image/Logo/logo-01.png'; 

// Redes sociales 
import facebookIcon from '../image/Redes sociales/Facebok.png';
import instagramIcon from '../image/Redes sociales/Imatragram.png';
import whatsappIcon from '../image/Redes sociales/whatsapp.png';
import tiktokIcon from '../image/Redes sociales/tiktok.png';


const suscripciones = [
  {
    nivel: 'Oro',
    precio: 600,
    precioEstudiante: 500,
    beneficios: [
      'Acceso a nutrimidad: app para dieta',
      'Acceso a rookmotion: App para sensor de seguimiento',
      'Dieta y educación',
      'Entrenamiento',
      'Seguimiento de peso y medidas',
      'Expediente de resultados mensuales'
    ]
  },
  {
    nivel: 'Plata',
    precio: 550,
    precioEstudiante: 480,
    beneficios: [
      'Acceso a rookmotion: App para sensor de seguimiento',
      'Seguimiento de peso y medidas',
      'Entrenamiento',
      'Expediente de resultados mensuales'
    ]
  },
  {
    nivel: 'Bronce',
    precio: 450,
    precioEstudiante: 400,
    beneficios: [
      'Entrenamiento',
      'Expediente de resultados mensuales',
      'Medida Mensual'
    ]
  }
];

function Suscripciones() {
  return (
    <div className="contenedor"> {/* Cambiado 'container' por 'contenedor' */}
      <header className="encabezadoSuscripciones"> {/* Cambiado 'headerSuscripciones' por 'encabezadoSuscripciones' */}
        <div className="logo"> {/* Cambiado 'logoS' por 'logo' */}
          <img src={Logo} alt="Backside Siljet" />
        </div>
        <h1>Suscripciones</h1>
        <nav>
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/instalaciones">Instalaciones</a></li>
            <li><a href="/Nosotros">Nosotros</a></li>
            <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
          </ul>
        </nav>
         <div className="iconos-sociales">
          <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw==" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://wa.me/5212381783232" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
          <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" />
          </a> 
        </div>
      </header>

      <section className="contenidoSuperior"> {/* Cambiado 'contentSup' por 'contenidoSuperior' */}
        {suscripciones.map((plan, index) => (
          <div className="plan" key={index}>
            <h2>{plan.nivel}</h2>
            <p><strong>Precio:</strong> ${plan.precio}</p>
            <p><strong>Precio Estudiante:</strong> ${plan.precioEstudiante}</p>
            <ul>
              {plan.beneficios.map((beneficio, i) => (
                <li key={i}>{beneficio}</li>
              ))}
            </ul>
            {/* <button className="botonSuscribir">Suscribirse</button>  //Cambiado 'subscribe-btn' por 'botonSuscribir' */}
          </div>
        ))}
      </section>

      <footer className="pieDePagina"> {/* Cambiado 'footer' por 'pieDePagina' */}
        <p>&copy; 2024 Gimnasio para Mujeres Backside Siluet . Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Suscripciones;
