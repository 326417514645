import React, { useState, useEffect } from 'react';
import '../Style/Colaboradores/EditarUsuario.css';

const EditarUsuario = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [formData, setFormData] = useState({
    nombreDeUsuario: '',
    contrasena: '',
    numeroDeCliente: '',
    turno: '',
    nombre: '',
    descripcion: '',
    fechaDeNacimiento: '',
    edad: '',
    numeroTelefonico: '',
    correoElectronico: '',
    ocupacion: ''
  });

  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const token = localStorage.getItem('token'); // Suponiendo que guardas el token en localStorage
        const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios', {
          headers: {
            'x-auth-token': token,
          },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setUsuarios(data);
      } catch (error) {
        console.error('Error al obtener usuarios', error);
      }
    };

    fetchUsuarios();
  }, []);

  const handleUsuarioChange = async (e) => {
    const usuarioId = e.target.value;
    if (usuarioId) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios/${usuarioId}`, {
          headers: {
            'x-auth-token': token,
          },
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const usuario = await response.json();
        setSelectedUsuario(usuario);
        setFormData({
          nombreDeUsuario: usuario.nombreDeUsuario || '',
          contrasena: '',
          numeroDeCliente: usuario.numeroDeCliente || '',
          turno: usuario.turno || '',
          nombre: usuario.nombre || '',
          descripcion: usuario.descripcion || '',
          fechaDeNacimiento: usuario.fechaDeNacimiento || '',
          edad: usuario.edad || '',
          numeroTelefonico: usuario.numeroTelefonico || '',
          correoElectronico: usuario.correoElectronico || '',
          ocupacion: usuario.ocupacion || ''
        });
      } catch (error) {
        console.error('Error al obtener usuario', error);
      }
    } else {
      setSelectedUsuario(null);
      setFormData({
        nombreDeUsuario: '',
        contrasena: '',
        numeroDeCliente: '',
        turno: '',
        nombre: '',
        descripcion: '',
        fechaDeNacimiento: '',
        edad: '',
        numeroTelefonico: '',
        correoElectronico: '',
        ocupacion: ''
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedUsuario) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios/${selectedUsuario._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(formData)
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        alert('Usuario actualizado');
      } catch (error) {
        console.error('Error al actualizar usuario', error);
      }
    }
  };

  return (
    <div className="editar-usuario-container">
      <h1>Editar Usuario</h1>
      <div className="select-usuario">
        <label htmlFor="usuario-select">Selecciona un usuario:</label>
        <select id="usuario-select" onChange={handleUsuarioChange}>
          <option value="">Seleccione un usuario</option>
          {usuarios.map((usuario) => (
            <option key={usuario._id} value={usuario._id}>
              {usuario.nombreDeUsuario}
            </option>
          ))}
        </select>
      </div>
      {selectedUsuario && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nombreDeUsuario">Nombre de Usuario:</label>
            <input
              type="text"
              id="nombreDeUsuario"
              name="nombreDeUsuario"
              value={formData.nombreDeUsuario}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="contrasena">Contraseña mas de 6 caracteres :</label>
            <input
              type="password"
              id="contrasena"
              name="contrasena"
              value={formData.contrasena}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="numeroDeCliente">Número de Cliente:</label>
            <input
              type="number"
              id="numeroDeCliente"
              name="numeroDeCliente"
              value={formData.numeroDeCliente}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="turno">Turno:</label>
            <input
              type="text"
              id="turno"
              name="turno"
              value={formData.turno}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="descripcion">Descripción:</label>
            <input
              type="text"
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="fechaDeNacimiento">Fecha de Nacimiento:</label>
            <input
              type="date"
              id="fechaDeNacimiento"
              name="fechaDeNacimiento"
              value={formData.fechaDeNacimiento}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="edad">Edad:</label>
            <input
              type="number"
              id="edad"
              name="edad"
              value={formData.edad}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="numeroTelefonico">Número Telefónico:</label>
            <input
              type="text"
              id="numeroTelefonico"
              name="numeroTelefonico"
              value={formData.numeroTelefonico}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="correoElectronico">Correo Electrónico:</label>
            <input
              type="email"
              id="correoElectronico"
              name="correoElectronico"
              value={formData.correoElectronico}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="ocupacion">Ocupación:</label>
            <input
              type="text"
              id="ocupacion"
              name="ocupacion"
              value={formData.ocupacion}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit">Actualizar Usuario</button>
        </form>
      )}
    </div>
  );
};

export default EditarUsuario;
