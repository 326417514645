import React from 'react';
import '../Style/PrimeraParte/Inicio.css';
import logo from '../image/Logo/logo-01.png';
// Redes sociales 
import facebookIcon from '../image/Redes sociales/Facebok.png';
import instagramIcon from '../image/Redes sociales/Imatragram.png';
import whatsappIcon from '../image/Redes sociales/whatsapp.png';
import tiktokIcon from '../image/Redes sociales/tiktok.png';

import ImgBienbenida from '../image/BienvenidasImg/Bienvenida 3.jpg';
// programas 
import Oxidativo from '../image/Pilares/Oxidativo.jpg';
import Clasico from '../image/Pilares/Clasico.jpg';
import Glucolitico from '../image/Pilares/Glucolitico.jpg';
import Hiperforia from '../image/Pilares/Hiperforia.jpg'

// Testimonio 
import Karla_Alvarado from '../image/Testimnio/Karla Alvarado .jpg';
import Itzel_Gamboa  from '../image/Testimnio/Itzel gamboa.jpg';
import Noemi_Cortez from '../image/Testimnio/Noemi Cortez.jpg';
import Eleaneth_Porras from '../image/Testimnio/Eleaneth Porras.jpg'
// Video
import video from '../image/Videos/lv_0_20240716153645.mp4';
// Beneficios 
import CorrerImg from '../image/Iconos/Corriendo.png';
import PesasImg from '../image/Iconos/levantar peso.png';
import Yoga from '../image/Iconos/yoga.png';
import Aerobis from '../image/Iconos/Aerobis.png';



const testimonios = [
  {
    contenido: "Me encuentro muy feliz,y a gusto con los avances y resultados que noto día con día, no solo físico sino el cambio emocional ya que también las personas a mi alrededor lo pueden notar. El destinar tiempo y dedicación a mi persona es fundamental para mi bienestar en general ya que siendo madre muchas veces nos negamos ese privilegio que es tener una vida saludable",
    autor: "- Karla Alvarado",
    imagen: Karla_Alvarado
  },
  {
    contenido: "Me ha encantado la experiencia que me ofrece Backside Siluet ya que al ser un gimnasio exclusivo para mujeres lo hace diferente pero sobre todo especial. Me gusta que las coachs estén al tanto de que realices  adecuadamente los ejercicios, pero sobre todo me gusta más el ambiente y la buena convivencia que hay . Llevo ya 8 meses en Backside y un punto a destacar es que las rutinas no se me hacen aburridas ya que cada mes es algo distinto. El hacer ejercicio me mantiene más activa durante mi rutina diaria sobre todo mejora mi estado de ánimo gracias Backside por cambiar mi vida ...",
    autor: "- Itzel Gamboa ",
    imagen: Itzel_Gamboa
  },
  {
    contenido: "Tome la decisión  de hacer ejercicio por salud, siento que es importante como mujeres tener la iniciativa de cuidar nuestro cuerpo, de no quedar en el intento y demostrar que somos capaces de lograr objetivos para nosotras mismas. Me gusta mucho el ambiente que hay, las rutinas son muy buenas me encantan, las entrenadoras siempre están pendientes de nosotras para cuidarnos que hagamos bien los ejercicios y sobre todo darnos ese ánimo ✨.",
    autor: "- Noemi Cortez",
    imagen: Noemi_Cortez
  },
  {
    contenido: "Llevo más de dos años entrenando en Backside y se ha vuelto parte esencial de mi vida. Me encanta porque las entrenadoras siempre están presentes, apoyándote y guiándote en cada rutina. Además, la nutrióloga te ayuda a alcanzar tus metas. Gracias a Backside, he ganado fuerza, seguridad y autoestima, además de conocer a personas que ahora son muy importantes para mí. Lo recomiendo para todas las mujeres que buscan un cambio positivo en su vida.",
    autor: "Eleaneth Porras",
    imagen: Eleaneth_Porras
  } 
];

const Inicio = () => {
  return (
    <div className="pagina-curves">
      <header>
        <div className="logoInicio">
          <img src={logo} alt="LogoInicio"/>
        </div>
        <nav>
          <ul>
            {/* Correciones */}
            <li><a href="/">Inicio</a></li>
            <li><a href="/Instalaciones">Instalaciones</a></li>
            <li><a href="/Nosotros">Nosotros</a></li>
            <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
            <li><a href="/InicioSesionCliente">Inicio Sesión</a></li>
          </ul>
        </nav>
        <button className="unirse-btn" onClick={() => window.location.href='/Formulario'}>Únete al club</button>
        <div className="iconos-sociales">
          <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw==" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://wa.me/5212381783232" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
          <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" />
          </a> 
        </div>

      </header>
      <main>
        <div className="seccion-video-bienvenida">
          <video autoPlay loop controls>
            <source src={video} type="video/mp4" />
            Tu navegador no soporta este video.
          </video>
          <div className="mensaje-bienvenida">
            <h3 className='titulo-bienvenida'>Bienvenida</h3>
            <p>¡Bienvenidos a Backside Siluet! Somos un gimnasio enfocado en el entrenamiento de mujeres. Nuestro objetivo es ayudarte a alcanzar tus metas de acondicionamiento físico y aumentar tu masa muscular. ¡Únete a nuestra comunidad y comienza tu viaje hacia una vida más saludable y activa!</p>
          </div>
        </div>

        <div className="seccion-hero">
          <img src={ImgBienbenida} alt="Hero"/>
          
          <button className="inscribete-btn" onClick={() => window.location.href='/Suscripciones'} >Inscríbete al club</button>
        </div>
        {/*  Beneficios */}
        <div className="seccion-Beneficios">
          <h2>Beneficios Transformadores del Ejercicio para las Mujeres</h2>
          
          <div className ="Beneficios">
            <img src={CorrerImg} alt="Beneficios 1"/> 
            <h4>Mejora de la Salud Cardiovascular  </h4>
            <p> El ejercicio regular, especialmente el cardiovascular, fortalece el corazón y mejora la circulación sanguínea, lo que reduce el riesgo de enfermedades cardíacas. También se mejora la capacidad pulmonar y se regula la presión arterial.</p>
          </div>
          <div className ="Beneficios">
            <img src={PesasImg} alt="Beneficios 2"/> 
            <h4>Fortalecimiento del Sistema Musculoesquelético</h4>
            <p>La práctica de ejercicios de resistencia y fuerza ayuda a aumentar la densidad ósea y fortalecer los músculos. Esto es particularmente importante para las mujeres, ya que reduce el riesgo de osteoporosis y lesiones.</p>
          </div>
          <div className ="Beneficios">
            <img src={Yoga} alt ="Beneficios 3"/> 
            <h4>Mejora del Estado de Ánimo y Reducción del Estrés</h4>
            <p>El ejercicio físico libera endorfinas, conocidas como las hormonas de la felicidad, que mejoran el estado de ánimo y reducen los niveles de estrés y ansiedad. También ayuda a combatir la depresión y mejorar la calidad del sueño.</p>
          </div>
          <div className='Beneficios'>
            <img src ={Aerobis} alt ="Beneficios 4"/> 
            <h4>Mantenimiento y Control del Peso Corporal</h4>
            <p>El ejercicio regular es esencial para quemar calorías y mantener un peso corporal saludable. Ayuda a aumentar el metabolismo y a regular el apetito, lo que facilita el control del peso a largo plazo. </p>
          </div>
          
          <div className="seccion-por-que">
            <h3>¿Por qué elegirnos?</h3>
            <ul>
              <li><strong>Exclusividad para Mujeres:</strong> Disfruta de un entorno cómodo y acogedor, diseñado específicamente para ti.</li>
              <li><strong>Transformación Integral:</strong> Nos enfocamos en tu bienestar físico, mental y emocional para una transformación completa.</li>
              <li><strong>Mejora de la Autoestima:</strong> Te ayudamos a sentirte segura y orgullosa de ti misma a través de programas individualizados.</li>
              <li><strong>Comunidad de Apoyo:</strong> Forma parte de una comunidad solidaria y motivadora que te impulsa a alcanzar tus metas.</li>
              <li><strong>Área de Vitalidad:</strong> Combinamos ejercicio, bienestar y desarrollo personal para ofrecerte una experiencia única.</li>
            </ul>
          </div>

        </div>

        {/* Programas  */}
        
        <div className="seccion-programas">
          <div className="programa">
            <img src={Oxidativo} alt="Programa 1"/>
            <h3>Oxidativo</h3>
            <p>Su principal objetivo es la oxidación de grasas, manteniendo una frecuencia cardíaca base, intercalando ejercicios de alta, moderada y baja intensidad.</p>
            
          </div>
          <div className="programa">
            <img src={Hiperforia} alt="Programa 2"/>
            <h3>Hipertrofia</h3>
            <p>Su principal objetivo es el incremento muscular en el tren inferior; glúteos, cuádriceps, femoral.</p>
            
          </div>
          <div className="programa">
            <img src={Glucolitico} alt="Programa 3"/>
            <h3>Glucolítico</h3>
            <p>Su principal objetivo es el trabajo anaeróbico de alta intensidad para utilizar los depósitos glucolíticos y terminar con una oxidación de O₂.</p>

          </div>
          <div className='programa'>
            <img src={Clasico} alt="Programa 4"/>
            <h3>Funcional</h3>
            <p>Enfocado en la tonificación muscular de grupos musculares superiores, inferiores o mixtos. Prevención de lesiones y mejora de capacidades físicas funcionales.</p>
          </div>

        </div>
       
       {/* Testimonio */}
        <div className="seccion-testimonios">
          <h2 className="testimonios-title">Testimonios</h2>
          <div className="carrusel">
            {testimonios.map((testimonio, index) => (
              <div key={index} className="testimonial">
                <div className="imagen">
                  <img src={testimonio.imagen} alt={`Imagen ${index}`} />
                </div>
                <p>{testimonio.contenido}</p>
                <p className="nombre">{testimonio.autor}</p>
              </div>
            ))}
          </div>
        </div>


      </main>
 
      <footer>
        <div className="footer-content">
          <div className="footer-logoInicio">
            <img src={logo} alt="Logo del Club" />
              </div>
                <div className="footer-info">
                  <h3>Contacto</h3>
                  <p>Dirección: 3 norte #206 centro Tehuacán, puebla.</p>
                  <p>Teléfono: +52 238 178 3232</p>
                  <p>Email: backside.siluet@gmail.com</p>
                </div>
                <div className="footer-links">
                  <h3>Enlaces Rápidos</h3>
                    <ul>
                      <li><a href="/">Inicio</a></li>
                      <li><a href="/Instalaciones">Instructores</a></li>
                      <li><a href="/Suscripciones">Suscripciones</a></li>
                      <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
                    </ul>
                    </div>
                    <div className="footer-social">
                      <h3>Síguenos</h3>
                      <div className="social-icons">
                        <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL"><img src={facebookIcon} alt="Facebook" /></a>
                        <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw=="><img src={instagramIcon} alt="Instagram" /></a>
                        <a href="https://wa.me/5212381783232"><img src={whatsappIcon} alt="WhatsApp" /></a>
                        <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1"><img src={tiktokIcon} alt="TikTok" /></a>
                      </div>
                  </div>
              </div>
            <div className="footer-bottom">
            <p>&copy; 2024 Gimnasio para Mujeres Backside Siluet. Todos los derechos reservados.</p>
        </div>
      </footer>

    </div>
  );
}

export default Inicio;
