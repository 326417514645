
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Style/PrimeraParte/InicioSecion.css';

const InicioSesion = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [numeroDeCliente, setNumeroDeCliente] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Resetear el error antes de una nueva solicitud
    try {
      const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios/iniciar-sesion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombreDeUsuario: username, contrasena: password, numeroDeCliente }),
      });
      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem('token', token);
        navigate('/InicioCliente'); // Redirigir al usuario a un panel de control o la página deseada
      } else {
        const data = await response.json();
        setError(data.msg || 'Error en las credenciales');
      }
    } catch (error) {
      setError('Error en el servidor');
    }
  };

  return (
    <div className="container">
      <div className="cliente-section">
        <h2>Inicio de Sesión para Clientes</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="cliente-username">Nombre de usuario:</label>
          <input
            type="text"
            id="cliente-username"
            name="cliente-username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="cliente-password">Contraseña:</label>
          <input
            type="password"
            id="cliente-password"
            name="cliente-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="numero-de-cliente">Número de Cliente:</label>
          <input
            type="text"
            id="numero-de-cliente"
            name="numero-de-cliente"
            value={numeroDeCliente}
            onChange={(e) => setNumeroDeCliente(e.target.value)}
          />
          <button type="submit" className="button">Iniciar Sesión</button>
          <div></div>
          <Link to="/InicioSesionTrabajador">
            <button type="button" className="button">Colaboradores</button>
          </Link>
          <Link to="/Nosotros">
            <button type="button" className="button">Atrás</button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default InicioSesion;
