import React from 'react';
import '../Style/PrimeraParte/Instalaciones.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Importa las imágenes para el carrusel 

// Espesificasion para mujeres 
import EspesialisadoMujer from '../image/Sistema backside/Aparatos especializados para mujeres/01.jpg';
import EspesialisadoMujer1 from '../image/Sistema backside/Aparatos especializados para mujeres/02.jpg';
import EspesialisadoMujer2 from '../image/Sistema backside/Aparatos especializados para mujeres/03.jpg';

// Enfoque Interal 
import EnfoqueIntegral from '../image/Sistema backside/Enfoque integral/01.jpg';
import EnfoqueIntegral1 from '../image/Sistema backside/Enfoque integral/02.jpg';
import EnfoqueIntegral2 from '../image/Sistema backside/Enfoque integral/03.jpg';
import EnfoqueIntegral3 from '../image/Sistema backside/Enfoque integral/04.jpg';
import EnfoqueIntegral4 from '../image/Sistema backside/Enfoque integral/05.jpg'

// Evaluaciones
import Evaluacion1 from '../image/Sistema backside/Evaluaciones  iniciales y seguimiento mensual/01.jpg';
import Evaluacion2 from '../image/Sistema backside/Evaluaciones  iniciales y seguimiento mensual/02.jpg';
import Evaluacion3 from '../image/Sistema backside/Evaluaciones  iniciales y seguimiento mensual/03.jpg';
import Evaluacion4 from '../image/Sistema backside/Evaluaciones  iniciales y seguimiento mensual/04.jpg'

// Programa de aimentacion

import Alimentacion from '../image/Sistema backside/Programa de alimentación saludable/App de_20240626_175319_0000.png';
import Alimentacion2 from '../image/Sistema backside/Programa de alimentación saludable/images (7).jpeg';
import Alimentacion3 from '../image/Sistema backside/Programa de alimentación saludable/images (8).jpeg';

//Rutina alta intensidad 

import AltaIntencidad1 from '../image/Sistema backside/Rutinas de alta intensidad/01.jpg';
import AltaIntencidad2 from '../image/Sistema backside/Rutinas de alta intensidad/02.png';
import AltaIntencidad3 from '../image/Sistema backside/Rutinas de alta intensidad/03.jpg';

//Rutina Cuadriceps 

import RutinaCuadricep from '../image/Sistema backside/Rutinas glúteos - cuádriceps/01.png';
import RutinaCuadricep1 from '../image/Sistema backside/Rutinas glúteos - cuádriceps/02.jpg';
import RutinaCuadricep2 from '../image/Sistema backside/Rutinas glúteos - cuádriceps/03.jpg';
import RutinaCuadricep3 from '../image/Sistema backside/Rutinas glúteos - cuádriceps/04.jpg';
import RutinaCuadricep4 from '../image/Sistema backside/Rutinas glúteos - cuádriceps/05.jpg';
import RutinaCuadricep5 from '../image/Sistema backside/Rutinas glúteos - cuádriceps/06.jpg'

// Rutinas superiores / funcionales 

import RutinaFuncionales from '../image/Sistema backside/Rutinas superiores - funcionales/01.jpg';
import RutinaFuncionales1 from '../image/Sistema backside/Rutinas superiores - funcionales/02.jpg';
import RutinaFuncionales2 from '../image/Sistema backside/Rutinas superiores - funcionales/03.jpg';
import RutinaFuncionales3 from '../image/Sistema backside/Rutinas superiores - funcionales/04.jpg'

// Imagnes Bienvenida 
import IMAGEN_BANNER from '../image/BienvenidasImg/Bienvenida 2.jpg';
import LOGO from '../image/Logo/logo-01.png';

// Redes sociales 
import facebookIcon from '../image/Redes sociales/Facebok.png';
import instagramIcon from '../image/Redes sociales/Imatragram.png';
import whatsappIcon from '../image/Redes sociales/whatsapp.png';
import tiktokIcon from '../image/Redes sociales/tiktok.png';

// Configuración del carrusel
const configuracion = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  fade: true,
  cssEase: 'linear'
};

const Instalacion = ({ titulo, imagenes, descripcion }) => (
  <div className="instalacion">
    <Slider {...configuracion}>
      {imagenes.map((imagen, indice) => (
        <div key={indice}>
          <img src={imagen} alt={titulo} />
        </div>
      ))}
    </Slider>
    <div className="contenido-instalacion">
      <h2>{titulo}</h2>
      <p>{descripcion}</p>
    </div>
  </div>
);

function Instalaciones() {
  return (
    <div className="instalaciones">
      <header className="encabezado-instalaciones">
        <div className="logo">
          <img src={LOGO} alt="Silueta de Backside" />
        </div>
        <nav>
          <ul>
            <li><Link to="/">Inicio</Link></li>
            <li><Link to="/Suscripciones">Suscripciones</Link></li>
            <li><Link to="/Nosotros">Nosotros</Link></li>
            <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
          </ul>
        </nav>
        <div className="iconos-sociales">
          <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw==" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://wa.me/5212381783232" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
          <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" />
          </a> 
        </div>
      </header>
      <main className="principal-instalaciones">
        <section className="banner">
          <img src={IMAGEN_BANNER} alt="Banner" />
          <div className="texto-banner">
            <h1>Contamos con nuestro único y propio sistema de entrenamiento Backside</h1>
          </div>
        </section>
        <section className="seccion-principal">
          <div className="cuadricula-instalaciones">
            <Instalacion
              titulo="Aparatos especializados para mujeres "
              imagenes={[EspesialisadoMujer, EspesialisadoMujer1, EspesialisadoMujer2]}
              descripcion="Nuestro gimnasio cuenta con equipos diseñados específicamente para la anatomía femenina, optimizando cada ejercicio para obtener resultados efectivos en la tonificación y fortalecimiento del cuerpo."
            />
            <Instalacion
              titulo="Enfoque integral "
              imagenes={[EnfoqueIntegral, EnfoqueIntegral1, EnfoqueIntegral2, EnfoqueIntegral3, EnfoqueIntegral4]}
              descripcion="Ofrecemos un programa que abarca ejercicio, nutrición y bienestar emocional, ayudando a las mujeres a alcanzar sus objetivos de manera equilibrada y sostenible."
            />
            <Instalacion
              titulo="Evaluaciones  iniciales y seguimiento mensual "
              imagenes={[Evaluacion1, Evaluacion2, Evaluacion3, Evaluacion4]}
              descripcion="Realizamos evaluaciones exhaustivas desde el primer día y monitoreamos tu progreso cada mes, asegurando que alcances tus metas de forma segura y efectiva."
            />
            <Instalacion
              titulo="Programa de alimentación saludable"
              imagenes={[Alimentacion, Alimentacion2, Alimentacion3]}
              descripcion=" Nuestro plan nutricional, accesible desde nuestra aplicación, complementa tu rutina de ejercicios para promover una pérdida de peso saludable. La app te permite llevar un control detallado y personalizado de tu alimentación, ayudándote a alcanzar tus metas de manera más efectiva y sostenida."
            />
            <Instalacion
              titulo="Rutinas de alta intensidad "
              imagenes={[AltaIntencidad1, AltaIntencidad2, AltaIntencidad3]}
              descripcion="Nuestras rutinas están diseñadas para quemar calorías y mejorar la resistencia en sesiones cortas pero efectivas, asegurando resultados rápidos."
            />
            <Instalacion
              titulo="Rutinas glúteos / cuádriceps "
              imagenes={[RutinaCuadricep, RutinaCuadricep1, RutinaCuadricep2, RutinaCuadricep3, RutinaCuadricep4, RutinaCuadricep5]}
              descripcion="Enfocamos nuestros ejercicios en fortalecer y tonificar los glúteos y cuádriceps, esculpiendo una figura más definida y poderosa."
            />
            <Instalacion
              titulo="Rutinas superiores / funcionales "
              imagenes={[RutinaFuncionales, RutinaFuncionales1, RutinaFuncionales2, RutinaFuncionales3]}
              descripcion="Estas rutinas se centran en mejorar la fuerza del tren superior y la funcionalidad general del cuerpo, garantizando un desarrollo armónico y equilibrado."
            />
          </div>
        </section>
      </main>
      <footer className="pie-de-pagina">
        <div className="footer-content">
          <div className="footer-logoInicio">
            <img src={LOGO} alt="Logo del Club" />
          </div>
          <div className="footer-info">
            <h3>Contacto</h3>
            <p>Dirección: 3 norte #206 centro Tehuacán, puebla.</p>
            <p>Teléfono: +52 238 178 3232</p>
            <p>Email: backside.siluet@gmail.com</p>
          </div>
          <div className="footer-links">
            <h3>Enlaces Rápidos</h3>
            <ul>
              <li><a href="/">Inicio</a></li>
              <li><a href="/Instalaciones">Instructores</a></li>
              <li><a href="/Suscripciones">Precios</a></li>
              <li><a href="https://danzaaerea.backsidesiluet.com/">Danza Aérea</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <h3>Síguenos</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com/backsidesiluet?mibextid=ZbWKwL"><img src={facebookIcon} alt="Facebook" /></a>
              <a href="https://www.instagram.com/backside.siluet.oficial?igsh=MWxlaWlkcWt5YjZnYw=="><img src={instagramIcon} alt="Instagram" /></a>
              <a href="https://wa.me/5212381783232"><img src={whatsappIcon} alt="WhatsApp" /></a>
              <a href="https://www.tiktok.com/@gymbackside?_t=8nTE4jMmBVO&_r=1 "><img src={tiktokIcon} alt="TikTok" /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Gimnasio para Mujeres Backside Siluet. Todos los derechos reservados.</p>
        </div>
      </footer>

    </div>
  );
}

export default Instalaciones;
