import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import '../Style/Client/GraficaProgreso.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphsScreen = () => {
  const [clientProgressData, setClientProgressData] = useState({});
  const [rankingData, setRankingData] = useState({});
  const [clienteId, setClienteId] = useState('');

  useEffect(() => {
    // Simulación de datos. Reemplaza esto con llamadas reales a tu API
    const fetchClientProgressData = async (clientId) => {
      // Aquí debes llamar a tu API para obtener los datos del cliente específico
      const response = await fetch(`https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/mediciones/progreso/${clientId}`);
      const data = await response.json();

      setClientProgressData({
        labels: data.map(item => new Date(item.fecha).toLocaleDateString()),
        datasets: [
          {
            label: 'Peso',
            data: data.map(item => item.peso),
            backgroundColor: 'rgba(106, 13, 173, 0.6)',
            borderColor: 'rgba(106, 13, 173, 1)',
            borderWidth: 1,
          },
          {
            label: 'IMC',
            data: data.map(item => item.imc),
            backgroundColor: 'rgba(13, 106, 173, 0.6)',
            borderColor: 'rgba(13, 106, 173, 1)',
            borderWidth: 1,
          },
        ],
      });
    };

    const fetchRankingData = async () => {
      // Aquí debes llamar a tu API para obtener los mejores resultados
      const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/mediciones/ranking');
      const data = await response.json();

      setRankingData({
        labels: data.map(item => `Cliente ${item.numeroDeCliente}`),
        datasets: [
          {
            label: 'IMC',
            data: data.map(item => item.imc),
            backgroundColor: 'rgba(13, 173, 106, 0.6)',
            borderColor: 'rgba(13, 173, 106, 1)',
            borderWidth: 1,
          },
        ],
      });
    };

    if (clienteId) {
      fetchClientProgressData(clienteId);
    }
    fetchRankingData();
  }, [clienteId]);

  const handleClientIdChange = (e) => {
    setClienteId(e.target.value);
  };

  const progressOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Progreso del Cliente',
      },
    },
  };

  const rankingOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ranking de IMC de Clientes',
      },
    },
  };

  return (
    <div className="graphs-screen">
      <h1>Gráficos</h1>
      
      <div className="input-container">
        <label htmlFor="clienteId">ID del Cliente:</label>
        <input 
          type="text" 
          id="clienteId" 
          value={clienteId} 
          onChange={handleClientIdChange} 
          placeholder="Ingrese ID del Cliente" 
        />
      </div>

      <div className="chart-container">
        {clientProgressData.labels && <Bar data={clientProgressData} options={progressOptions} />}
      </div>

      <div className="chart-container">
        {rankingData.labels && <Bar data={rankingData} options={rankingOptions} />}
      </div>
    </div>
  );
};

export default GraphsScreen;
