
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Style/PrimeraParte/InicioSecionTrabajador.css';

const InicioSecionTrabajador = () => {
  const [nombreDeUsuario, setNombreDeUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Resetear el error antes de una nueva solicitud
    try {
      const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/trabajadores/login', {  // Cambiar a puerto 5000
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombreDeUsuario, contrasena }),
      });
  
      if (response.ok) {
        const { token } = await response.json();
        localStorage.setItem('token', token);
        navigate('/InicioColaboradores'); // Redirigir a una ruta válida
      } else {
        const data = await response.json();
        setError(data.msg || 'Error en las credenciales');
      }
    } catch (error) {
      setError('Error en el servidor');
    }
  };
  

  return (
    <div className="container">
      <div className="trabajador-section">
        <h2>Inicio de Sesión para Trabajadores</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="trabajador-username">Nombre de usuario:</label>
          <input
            type="text"
            id="trabajador-username"
            name="trabajador-username"
            value={nombreDeUsuario}
            onChange={(e) => setNombreDeUsuario(e.target.value)}
          />
          <label htmlFor="trabajador-password">Contraseña:</label>
          <input
            type="password"
            id="trabajador-password"
            name="trabajador-password"
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
          />
          <button type="submit" className="button">Iniciar Sesión</button>
        </form>
      </div>
    </div>
  );
};

export default InicioSecionTrabajador;
