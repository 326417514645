import React, { useState } from 'react';
import '../Style/Client/Medicion.css' 
const RegistroMedicion = () => {
    const [formData, setFormData] = useState({
        numeroDeCliente: '',
        estatura: '',
        peso: '',
        porcentajeDeGrasa: '',
        imc: '',
    });
    const [mensaje, setMensaje] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/mediciones/registrar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (response.ok) {
                setMensaje(data.msg);
                setFormData({
                    numeroDeCliente: '',
                    estatura: '',
                    peso: '',
                    porcentajeDeGrasa: '',
                    imc: '',
                });
            } else {
                setMensaje('Error al registrar medición');
            }
        } catch (error) {
            setMensaje('Error al registrar medición');
        }
    };

    return (
        <div className="form-container">
            <h2>Registrar Medición</h2>
            <form onSubmit={handleSubmit}>
                <input type="number" name="numeroDeCliente" placeholder="Número de Cliente" onChange={handleChange} value={formData.numeroDeCliente} required />
                <input type="number" name="estatura" placeholder="Estatura (cm)" onChange={handleChange} value={formData.estatura} required />
                <input type="number" name="peso" placeholder="Peso (kg)" onChange={handleChange} value={formData.peso} required />
                <input type="number" name="porcentajeDeGrasa" placeholder="Porcentaje de Grasa (%)" onChange={handleChange} value={formData.porcentajeDeGrasa} required />
                <input type="number" name="imc" placeholder="IMC" onChange={handleChange} value={formData.imc} required />
                <button type="submit">Registrar</button>
            </form>
            {mensaje && <p className="message">{mensaje}</p>}
        </div>
    );
};

export default RegistroMedicion;
