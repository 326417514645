import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../Style/PrimeraParte/Formulario.css';
import logo from '../image/Logo/logo-01.png';

function Formulario() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    edad: '',
    experiencia: '',
    metas: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Define el contenido del correo
    const emailParams = {
      from_name: formData.nombre,
      from_email: formData.email,
      message: `
        Nombre: ${formData.nombre}\n
        Email: ${formData.email}\n
        Teléfono: ${formData.telefono}\n
        Edad: ${formData.edad}\n
        Experiencia previa: ${formData.experiencia}\n
        Metas: ${formData.metas}
      `,
    };

    // Envía el correo usando EmailJS
    emailjs.send(
      'service_bb5xcsg', // Reemplaza con tu Service ID
      'template_by47z52', // Reemplaza con tu Template ID
      emailParams,
      'CH0vg3Gxq93eGxFOQ' // Reemplaza con tu Public Key
    )
    .then((response) => {
      alert('Datos enviados con éxito!');
      setFormData({
        nombre: '',
        email: '',
        telefono: '',
        edad: '',
        experiencia: '',
        metas: '',
      });
    })
    .catch((err) => {
      alert('Error al enviar los datos, inténtelo de nuevo.');
      console.error('Error:', err);
    });
  };

  return (
    <div className="form-container">
      <img src={logo} alt="Logo" className="LogoFormulario" />
      <h1>Formulario de Registro</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Teléfono:
          <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} required />
        </label>
        <label>
          Edad:
          <input type="number" name="edad" value={formData.edad} onChange={handleChange} required />
        </label>
        <label>
          Experiencia previa en gimnasios:
          <textarea name="experiencia" value={formData.experiencia} onChange={handleChange} required />
        </label>
        <label>
          Metas y objetivos:
          <textarea name="metas" value={formData.metas} onChange={handleChange} required />
        </label>
        <button type="submit">Enviar</button>
        <button className="Atras" onClick={() => window.location.href='/'}>Atras</button>
      </form>
    </div>
  );
}

export default Formulario;
