import React from 'react';
import { Link } from 'react-router-dom';
import '../Style/Client/Inicio.css';

const InicioCliente = () => {
  return (
    <div className="user-screen">
      <h1> Bienvenida a backside siluft </h1>
      <div className="sections">
        <Link to="/Medicion" className="section">Actualización de Estado</Link>
        <Link to="/RutinasVideos" className="section">Rutinas y Videos</Link>
        <Link to="/GraficaProgreso" className="section">Gráficos</Link>
      </div>
    </div>
  );
}

export default InicioCliente;
