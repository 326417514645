import React, { useState } from 'react';
import '../Style/Colaboradores/BusquedaCliente.css';

const BusquedaCliente = () => {
  const [nombre, setNombre] = useState('');
  const [nombreDeUsuario, setNombreDeUsuario] = useState('');
  const [numeroDeCliente, setNumeroDeCliente] = useState('');
  const [resultados, setResultados] = useState([]);
  const [mensaje, setMensaje] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      if (nombre) params.append('nombre', nombre);
      if (nombreDeUsuario) params.append('nombreDeUsuario', nombreDeUsuario);
      if (numeroDeCliente) params.append('numeroDeCliente', numeroDeCliente);

      const response = await fetch(`https://backside-siluet-1c6436f1c1f3.herokuapp.com/api/usuarios/buscar?${params.toString()}`);

      if (response.ok) {
        const data = await response.json();
        if (data.length === 0) {
          setMensaje('No se encontraron usuarios');
        } else {
          setMensaje('');
          setResultados(data);
        }
      } else {
        const errorText = await response.text();
        console.error('Error en la búsqueda:', errorText);
        setMensaje('Error en la búsqueda');
      }
    } catch (err) {
      console.error('Error en la búsqueda:', err);
      setMensaje('Error en la búsqueda');
    }
  };

  return (
    <div className="busqueda-cliente">
      <h1>Búsqueda de Usuario</h1>
      <form onSubmit={handleSubmit} className="busqueda-formulario">
        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </div>
        <div className="campo">
          <label>Nombre de Usuario:</label>
          <input
            type="text"
            value={nombreDeUsuario}
            onChange={(e) => setNombreDeUsuario(e.target.value)}
          />
        </div>
        <div className="campo">
          <label>Número de Cliente:</label>
          <input
            type="text"
            value={numeroDeCliente}
            onChange={(e) => setNumeroDeCliente(e.target.value)}
          />
        </div>
        <button type="submit" className="boton">Buscar</button>
      </form>
      {mensaje && <p>{mensaje}</p>}
      <div className="resultados">
        {resultados.map((usuario) => (
          <div key={usuario._id} className="resultado">
            <p><strong>Nombre:</strong> {usuario.nombre}</p>
            <p><strong>Nombre de Usuario:</strong> {usuario.nombreDeUsuario}</p>
            <p><strong>Número de Cliente:</strong> {usuario.numeroDeCliente}</p>
            <p><strong>Turno:</strong> {usuario.turno}</p>
            <p><strong>Descripsion del Membresia:</strong> {usuario.descripcion}</p>
            <p><strong>Edad: </strong> {usuario.edad}</p>
            <p><strong>Numero telefonico: </strong> {usuario.numeroTelefonico}</p>
            <p><strong>Correo electronico: </strong> {usuario.correoElectronico}</p>
            <p><strong>Ocupasion: </strong> {usuario.ocupacion}</p>

          </div>
        ))}
      </div>
    </div>
  );
};

export default BusquedaCliente;
