import React, { useState, useEffect } from 'react';
import '../Style/Colaboradores/Capacitacion.css';

// Enlaces de Mega (debes reemplazar estos enlaces con los correctos)
const PrimeraParte = 'https://mega.nz/file/bAoFFDKY#SJN54i6GXBk82CE1D49KOye1c2tAAmr-1szSoTDh3RI';
const PrimeraParte1 = 'https://mega.nz/file/CEpxwRaa#-ikjiKqeF-lAj70PB14LjW3LkADByE8fe-0JdRxKM-c';

const SegundaParte = 'https://mega.nz/file/vE5gXZpI#v5b7ULkQ86Cd-Bn4kj8Nf73o1-Be87aS4bdErEGqdIM';
const SegundaParte2 = 'https://mega.nz/file/LQJCATIK#Y4AOSkXFmkbjgA2ka2OG15Y477gWjv8O3ij9Fb7S4Pw';
const SegundaParte3 = 'https://mega.nz/file/aB5DHIxD#0Q3pB095kVNjye9J-sxBgwSxuW_1o5V7bbvFU2MtHls';
const SegundaParte4 = 'https://mega.nz/file/rdIizL5R#taFXUpjlC07yU6DHrRRW8S_Hcp6z_lzVOwuS2lg2chw';

const videosData = {
  abdomen: [
    { id: 1, title: 'Introducción', src: PrimeraParte },
    { id: 2, title: 'Parte 1', src: PrimeraParte1 },
  ],
  cardio: [
    { id: 3, title: 'Segunda Parte Introducción', src: SegundaParte },
    { id: 4, title: 'Capítulo 2', src: SegundaParte2 },
    { id: 5, title: 'Capítulo 3', src: SegundaParte3 },
    { id: 6, title: 'Capítulo 4', src: SegundaParte4 },
  ],
};

const Capacitacion = () => {
  const [videos, setVideos] = useState(videosData.abdomen);
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const [videoCategory, setVideoCategory] = useState('abdomen');

  useEffect(() => {
    setCurrentVideo(videos[0]);
  }, [videos]);

  const handlePrevious = () => {
    const currentIndex = videos.findIndex(video => video.id === currentVideo.id);
    const previousIndex = (currentIndex - 1 + videos.length) % videos.length;
    setCurrentVideo(videos[previousIndex]);
  };

  const handleNext = () => {
    const currentIndex = videos.findIndex(video => video.id === currentVideo.id);
    const nextIndex = (currentIndex + 1) % videos.length;
    setCurrentVideo(videos[nextIndex]);
  };

  const handleCategoryChange = (category) => {
    setVideoCategory(category);
    setVideos(videosData[category]);
  };

  return (
    <div className="capacitacion-container">
      <div className="button-container">
        <button onClick={() => handleCategoryChange('abdomen')} className="category-button">
          Primera Parte
        </button>
        <button onClick={() => handleCategoryChange('cardio')} className="category-button">
          Segunda Parte
        </button>
      </div>
      <div className="video-player">
        <video controls src={currentVideo.src} width="100%" preload="metadata"></video>
        <div className="video-controls">
          <button onClick={handlePrevious}>Video Anterior</button>
          <button onClick={handleNext}>Siguiente Video</button>
        </div>
        <div className="rules-section">
          <h2>Reglas</h2>
          <ul>
            <li>No hacer mal uso de este contenido.</li>
            <li>No generar ambiente conflictivo.</li>
          </ul>
        </div>
      </div>
      <div className="video-list">
        <h2>Más Videos</h2>
        <ul>
          {videos.map(video => (
            <li key={video.id} onClick={() => setCurrentVideo(video)}>
              {video.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Capacitacion;
